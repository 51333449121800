import { If } from '@naamdeo/react-lib/control-flow/If';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@naamdeo/shadcn-ui/components/card';
import { Progress } from '@naamdeo/shadcn-ui/components/progress';
import { Skeleton } from '@naamdeo/shadcn-ui/components/skeleton';
import { cn } from '@naamdeo/shadcn-ui/lib/utils';
import { Layers, AlarmClock, Star, Users2 } from 'lucide-react';
import { useMemo } from 'react';
import { getCourseDurationDisplay } from '~/utils/display';

export type CourseCardProps = {
  title: string;
  price?: number;
  rating?: number;
  progress?: number;
  thumbnail: string;
  slug: string;
  chapters?: number;
  durationSeconds: number;
  enrollmentCount?: number;
  isUpcoming?: boolean;
};

export function CourseCard(props: CourseCardProps) {
  const durationString = useMemo(() => getCourseDurationDisplay(props.durationSeconds), [props.durationSeconds]);
  const thumbnailUrl = new URL(props.thumbnail);

  return (
    <Card className={cn('max-w-2xl', { 'opacity-60': props.isUpcoming })}>
      <div className="relative aspect-video">
        <img
          src={`https://public-assets.naamdeo.org/cdn-cgi/image/width=640,quality=90,format=auto${thumbnailUrl.pathname}`}
          alt="self-governance"
          className={cn('h-full w-full rounded-t-lg object-cover')}
        />
        <If condition={props.price}>
          <div className="bg-ternary absolute right-2 top-2 rounded-md px-2 py-1">
            <p className="text-lg text-white">₹ {props.price}</p>
          </div>
        </If>
      </div>
      <CardHeader className="flex justify-between gap-x-2 pb-8">
        <CardTitle className="font-heading flex items-start justify-between text-primary">
          <div>{props.title}</div>
          <If condition={props.rating}>
            <div className="flex items-center gap-x-1">
              <Star size={20} className="text-primary" />
              <p className="font-base text-ternary text-lg font-medium">{props.rating}</p>
            </div>
          </If>
        </CardTitle>
      </CardHeader>
      <If condition={props.progress}>
        <CardContent className="pb-8">
          <p className="text-ternary mb-1">{props.progress}% Completed</p>
          <Progress value={props.progress} className="h-2"></Progress>
        </CardContent>
      </If>
      <CardFooter className="font-base flex justify-between gap-2 p-4 pt-0 text-sm">
        <If condition={props.chapters}>
          <div>
            <div className="flex items-center gap-x-1">
              <Layers size={18} className="text-primary" />
              <p className="font-medium">{props.chapters} Chapters</p>
            </div>
          </div>
        </If>
        <If condition={props.durationSeconds}>
          <div>
            <div className="flex gap-x-1">
              <AlarmClock size={20} className="text-primary" />
              <p className="font-medium">{durationString}</p>
            </div>
          </div>
        </If>
        <If condition={props.isUpcoming}>
          <div>
            <p className="text-ternary font-medium">Coming soon...</p>
          </div>
        </If>
        <If condition={props.enrollmentCount}>
          <div>
            <div className="flex gap-x-1">
              <Users2 size={20} className="text-primary" />
              <p className="font-medium">{props.enrollmentCount} Enrolled</p>
            </div>
          </div>
        </If>
      </CardFooter>
    </Card>
  );
}

export function CourseCardSkeleton() {
  return (
    <div className="overflow-hidden rounded-md border">
      {/* Course Thumbnail Skeleton */}
      <div className="relative aspect-video">
        <Skeleton className="h-[200px] w-full rounded-t-lg" />
        {/* Price Skeleton */}
        <div className="absolute right-2 top-2 rounded-md">
          <Skeleton className="h-6 w-12" />
        </div>
      </div>

      {/* Course Title & Rating Skeleton */}
      <div className="flex items-center justify-between gap-x-2 p-4">
        <Skeleton className="h-6 w-3/5" />
        <div className="flex items-center gap-x-2">
          <Skeleton className="h-5 w-5 rounded-full" />
          <Skeleton className="h-5 w-10" />
        </div>
      </div>

      {/* Course Progress Skeleton */}
      <div className="p-4">
        <Skeleton className="mb-2 h-4 w-1/2" />
        <Skeleton className="h-2 w-full" />
      </div>

      {/* Course Details Skeleton */}
      <div className="flex items-center justify-between gap-2 p-4 text-sm">
        <div className="flex items-center gap-2">
          <Skeleton className="h-5 w-5 rounded-full" />
          <Skeleton className="h-4 w-20" />
        </div>
        <div className="flex items-center gap-2">
          <Skeleton className="h-5 w-5 rounded-full" />
          <Skeleton className="h-4 w-20" />
        </div>
        <div className="flex items-center gap-2">
          <Skeleton className="h-5 w-5 rounded-full" />
          <Skeleton className="h-4 w-20" />
        </div>
      </div>
    </div>
  );
}
